export const cs = {
    common: {
        AllCatsLabel: "<Všechny>",
        Category: "Kategorie",
        Begin: "Začátek",
        Cancel: "Zrušit",
        Center: "Středisko",
        Close: "Zavřít",
        Confirm: "Potvrdit",
        Day: "Den",
        Delete: "Smazat",
        DeleteAll: "Smazat vše",
        End: "Konec",
        ErrorNotification: "Došlo k neočekávané chybě.",
        ErrorNotificationClient: "Data nejsou dostupná, zkontrolujte připojení.",
        ErrorNotificationServer: "Došlo k chybě na serveru, kontaktujte správce.",
        For: "Pro",
        Person: "Osoba",
        Change: "Změnit",
        Help: "Nápověda",
        Label: "Popisek",
        Language: "Jazyk",
        NameNote: "Název",
        No: "Ne",
        Approve: "Odsouhlasit",
        Deny: "Zamítnout",
        NoItems: "Žádné položky",
        OC: "Osobní číslo",
        Save: "Uložit",
        Shortcut: "Zkratka",
        Yes: "Ano",
        continue: "Pokračovat",
    },
    app: {
        ConfigurationDownloading: "Stahování konfigurace",
        LicenseChecking: "Kontrola licence",
        ServerChecking: "Kontrola serveru",
        Msg_AppDisabledByLicense: "Použití aplikace je zakázáno licencí.",
        PageNotFound: "Stránka nenalezena.",
        PageNotFoundTitle: "404",
    },
    dashboard: {
        Balance: "Saldo",
        BalanceError: "Chyba salda",
        VacationBalance: "Zůstatek dovolené",
        EmailForNotifications: "Email pro notifikace",
        ChangePerson: "Změnit osobu",
        DepartmentPlan: "Plán oddělení",
        FreeShifts: "Volné směny",
        Logout: "Odhlásit",
        MyAccount: "Můj účet",
        MyPlan: "Můj plán",
        MyRequests: "Moje požadavky",
        MyUnavailabilities: "Moje nedostupnost",
        OnlineTerminal: "Terminál",
        MyWorksheet: "Můj pracovní list",
        Reports: "Reporty",
        RequestsToApprove: "Požadavky ke schválení",
        RequestsToApprovePlnunava: "Schvalování nedostupností",
        PersonalCard: "Karta osoby",
        PlansStates: "Stavy plánů",
        Presence: "Přítomnost",
        WorkSheetStates: "Stavy pracovních listů",
        NotComputed: "Nespočteno",
        Days: "Dní",
        Hours: "Hodiny",
        Hours4: "Hodin",
        AdditionalVacation: "Zůstatek dodatkové dovolené I",
        AdditionalVacation2: "Zůstatek dodatkové dovolené II",
        Overtime: "Moje přesčasy",
    },
    lastPasses: {
        TitleLP: "Průchody",
        NoPasses: "Žádné průchody...",
    },
    login: {
        Username: "Uživatelské jméno",
        Password: "Heslo",
        Remember: "Zapamatovat údaje",
        Login: "Přihlásit",
        LoginDialog: "Přihlašovací dialog",
        CustomerCode: "Kód zákazníka",
        CustomerCodeChange: "Změnit kód zákazníka",
        login_error_user_not_registered: "Chyba přihlášení: Uživatel nemá založen účet pro WebTime.",
        login_error_invalid_grant: "Neplatné uživatelské jméno nebo heslo.",
        login_error_invalid_grant_multitenant: "Neplatné uživatelské jméno, heslo nebo kód zákazníka.",
        login_error_locked_account: "Účet dočasně zablokován na {0} minut.",
        login_error_app_usage_not_allowed: "Neplatné uživatelské jméno nebo heslo.",
        login_error_user_expired: "Osoba mimo platnost.",
        //login_error_app_usage_not_allowed: 'Nemáte povoleno používat tuto aplikaci.',
    },
    passwordChange: {
        title: "Změna hesla",
        oldPassword: "Původní heslo",
        newPassword: "Nové heslo",
        newPasswordVerification: "Potvrzení nového hesla",
        error_not_same: "Nová hesla se neshodují!",
        error_contains_username: "Heslo nesmí obsahovat uživatelské jméno",
        error_contains_3_chars: "Heslo nesmí obsahovat sekvenci 3 opakujících se znaků",
        error_contains_sequence: "Heslo nesmí obsahovat stoupající nebo klesající posloupnost znaků",
        error_password_in_history: "Nové heslo je identické s některým heslem v historii!",
        error_invalid_old_password: "Původní heslo není platné!",
    },
    reports: {
        Params: "Zadejte parametry reportu",
        LoadReport: "Načíst",
    },
    requests: {
        Approve: "Schválit",
        Approved: "Schválen",
        ApprovedBy: "Schválil",
        CancelRequest: "Zrušit požadavek",
        Canceled: "Zrušen",
        Comment: "Poznámka",
        CreatedReq: "Vytvořen",
        DaysUpTo4: "dny",
        DefaultAccount: "Vyberte účet",
        ErrorRequiredNote: "Pro zamítnutí je potřeba zapsat poznámku.",
        ErrorRequiredNoteUnava: "Pro zadání požadavku je povinná poznámku.",
        Expired: "Po termínu",
        ForPerson: "Pro osobu",
        FromDate: "Od",
        Fullday: "Celodenní",
        IsWrongDayOrder: "Den konce nemůže být před dnem začátku!",
        NewReq: "Nový",
        NewRequest: "Nový požadavek",
        NewRequestFor: "Nový požadavek pro...",
        NoData: "Žádné požadavky...",
        NoAccounts: "Žádné účty pro plánování požadavků",
        Pending: "Čekající",
        Reject: "Zamítnout",
        Rejected: "Zamítnut",
        RejectedElectronically: "Zamítnut elektronicky",
        RejectedHand: "Zamítnut ručně",
        RejectedBy: "Zamítnul",
        RemainingDay: "Do konce seznámení zbývá 1 den",
        RemainingDays: "Do konce seznámení zbývá $0$ dní",
        RemainingDaysUpTo4: "Do konce seznámení zbývájí $0$ dny",
        RequestSingle: "Požadavek",
        ReqForApproval: "Požadavky",
        Search: "Hledat...",
        ShowOnly: "Jen čekající požadavky",
        StateName: "Stav",
        ToDate: "Do",
        Type: "Typ",
        SelectAll: "Vybrat vše",
        ApproveSelected: "Schválit vybrané požadavky?",
        RejectSelected: "Zamítnout vybrané požadavky?",
        MassApprove: "Hromadné schvalování",
    },
    terminals: {
        GPSText: "na souřadnicích",
        ChoosePass: "Vyberte průchod",
        NotificationText: "Byl vložen průchod typu",
        NotificationEnText: "",
        NotificationErrorText: "Průchod nebyl vložen! Zkontrolujte připojení",
        Settings: "Nastavení",
        TitleTerminals: "Terminál",
        NoPass: "<Žádný průchod>",
    },
    personalCard: {
        EmploymentNumber: "Číslo prac. poměru",
        Phone: "Telefon",
        Profession: "Profese",
        ValidFrom: "Platný od",
        ValidTo: "Platný do",
        LastPassage: "Poslední průchod",
    },
    unavailability: {
        CreatedUnavailability: "Vytvořeno",
        New: "Nová",
        NewUnavailability: "Nová nedostupnost",
        NoteComment: "Poznámka",
        TimeFrom: "Čas od",
        TimeTo: "Čas do",
        Unavailability: "Mé nedostupnosti",
        Unavailabilities: "Nedostupnosti",
    },
    worksheet: {
        Absence: "Abs.",
        AccountDefName: "Účet",
        ApprovedWS: "Schváleno",
        BalanceNext: "Saldo v příštím období",
        CalendarFund: "Fond dle kalendáře sjednaný/stanovený",
        ComputeTo: "Počítat do",
        Confirmed: "Potvrzeno",
        DaysWS: "Dny",
        ErrorWS: "Chyba",
        Model: "Model",
        MonthAccounts: "Měsíční účty",
        MyWorksheetTitle: "Pracovní list",
        NameWS: "Jméno",
        NoDataWorksheetNull: "Žádná data k zobrazení, zkontrolujte přístup na svou osobu.",
        PassesWS: "Průchody",
        Period: "Období",
        ShiftsFund: "Fond dle směn",
        Sign: "Podepsat",
        Signature: "Podepsal",
        SignTitle: "Podepsání pracovního listu",
        SignatureNeededWarning: "Podepište si svůj pracovní list!",
        State_Approved: "Schválený",
        State_Confirmed: "Potvrzený",
        State_Open: "Otevřený",
        State_SignedElectronically: "Podepsaný elektronicky",
        State_SignedHandwritten: "Podepsaný ručně",
        TransferPast: "Převod z minula",
        TransferNext: "Převod do dalšího období",
        Worked: "Odpr.",
        Attachments: "Přílohy",
        NoAttachments: "Nejsou přiloženy žádné přilohy.",
        TakePicture: "Vyfotit",
        AttachmentName: "Název",
        AttachmentNote: "Poznámka",
        NewAttachemnt: "Nová přiloha",
        Upload: "Nahrát",
    },
    worksheetStates: {
        TitleWSStates: "Stavy PL",
        AllChosen: "Vše",
        OnlyApprovedWS: "Jen schválené",
        OnlyCreated: "Jen otevřené",
        OnlyConfirmed: "Jen potvrzené",
        OnlySigned: "Jen podepsané elektronicky",
        OnlySignedHandwritten: "Jen podpsané ručním podpisem",
        OnlyUnSigned: "Jen nepodepsané",
    },
    planStates: {
        Application: "Aplikace",
        Checked: "Seznámen elektronicky",
        CheckedHand: "Seznámen ručně",
        Unchecked: "Neseznámen",
        Checkedby: "Seznámil",
        ChangeFrom: "Změna od",
        ChangedBy: "Odsouhlasil",
        DateFrom: "Období od",
        DateTo: "Období do",
        DetailCheck: "Detail seznámení",
        DetailRequest: "Detail požadavku na změnu",
        NotPassed: "Nepředaný",
        OnlyNotPassed: "Jen nepředané",
        OnlyChecked: "Jen seznámené elektronicky",
        OnlyCheckedHand: "Jen seznámené ručně",
        OnlyUnchecked: "Jen neseznámené",
        OnlyApprovedPS: "Jen odsouhlasené elektronicky",
        OnlyApprovedPSHand: "Jen odsouhlasené ručně",
        OnlyRejected: "Jen zamítnuté elektronicky",
        OnlyRejectedHand: "Jen zamítnuté ručně",
        Waiting: "Čekající na odsouhlasení",
        OnlyWaiting: "Jen čekající na odsouhlasení",
        ApprovedState: "Odsouhlasen elektronicky",
        ApprovedStateHand: "Odsouhlasen ručně",
        PreferedKind: "Preferovaný způsob",
        TitlePS: "Stavy plánů",
    },
    plan: {
        WasSubmittedBy: "Předal k seznámení",
        PlanWasChangedBy: "Plán změnil",
        Legend: "Legenda",
        DetailPlan: "Detail",
        RequestDetail: "Detail požadavku",
        PlanChange: "Změna plánu",
        ApprovalOfPlanChanges: "Odsouhlasení změn plánu",
        AcquaintanceWithPlan: "Seznámení s plánem",
        Msg_ConfirmAcquaintWithYourPlanPlease: "Potvrďte prosím seznámení se svým plánem.",
        Msg_ApprovePlanChanges: "Odsouhlaste změny v plánu.",
        PlanName: "Plán",
        N3Days: "3-Dny",
        Week: "Týden",
        Msg_TooMuchPersons: 'Středisko "{0}" zahrnuje víc osob než je povoleno načíst ({1}).',
        OutsideOfWorkplace: "Mimo pracoviště",
        Recalculate: "Přepočítat plány",
        NotPublished: "Pro tento měsíc nebyl předán plán k seznámení.",
        ContainsUnpublished: "Tento měsíc obsahuje období, pro které nebyl předán plán k seznámení.",
    },
    presence: {
        DetailPresence: "Detail přítomnosti",
        AllPersons: "Všechny osoby",
        Present: "Přítomen",
        OnlyInconsistent: "Jen s nesrovnalostmi přítomnosti",
        OnlyNotPresent: "Pouze nepřítomné osoby",
        OnlyPresent: "Pouze přítomné osoby",
        OnlyWithShift: "Jen s naplánovanou směnou",
        LastPassName: "Poslední průchod",
        LastPassTime: "Čas posledního průchodu",
        MaxCount: 'Středisko "{0}" zahrnuje víc osob než je povoleno načíst (1000).',
        ShiftName: "Směna",
        Absent: "Nepřítomen",
        Unknown: "Nezjištěn",
        TerminalPres: "Terminál",
        TitlePresence: "Přítomnost",
    },
    overtime: {
        Title: "Přesčasy",
        DialogTitle: "Přesčas",
        Day: "Den",
        Note: "Poznámka",
        From: "Začátek",
        To: "Konec",
        Account: "Účet",
    },
    debug: {
        ErrorsMany: "Chyby",
        ShowError: "Zobrazit chyby",
        Msg_ClickForCopyDetailsIntoClipboard: "Klikněte pro zkopírování podrobností do schránky.",
        Msg_DetailsSuccessfullyCopiedIntoClipboard: "Podrobnosti úspěšně zkopírovány do schránky.",
    },
    centerPicker: {
        NotSelected: "Nevybráno",
        NoSubcenters: "Žádná podstřediska",
        IncludingSubcenters: "Včetně podstředisek",
    },
    responses: {
        over_limit: "Překročen zůstatek dovolené.",
        period_locked: "Pracovní list pro dané období je již potvrzen.",
        attachment_upload_success: "Přiloha byla nahrána.",
        attachment_upload_error: "Nahrání přílohy se nezdařilo.",
        date_reserved_unava: "Vybrané období již obsahuje nedostupnost.",
        license_error: "Počet uživatelů SelfService překračuje počet licencí.",
    },
    actions: {
        DepartmentPlanSelfServiceApp_RunApp: "Plán oddělení",
        MyPlanSelfServiceApp_RunApp: "Můj plán",
        MyRequestsSelfServiceApp_RunApp: "Moje požadavky",
        MyUnavailabilitiesSelfServiceApp_RunApp: "Moje nedostupnosti",
        MyWSSelfServiceApp_RunApp: "Můj pracovní list",
        OvertimeSelfServiceApp_RunApp: "Moje přesčasy",
        PlanStatesSelfServiceApp_RunApp: "Stavy plánů",
        PresenceSelfServiceApp_RunApp: "Přítomnost",
        ReportsSelfServiceApp_RunApp: "Reporty",
        RequestsApprovalSelfServiceApp_RunApp: "Požadavky ke schválení",
        TerminalSelfServiceApp_RunApp: "Terminál",
        UnavasApprovalSelfServiceApp_RunApp: "Nedostupnosti ke schválení",
        WorksheetStatesSelfServiceApp_RunApp: "Stavy pracovních listů",
    },
    substitutions: {
        Person: "Osoba:",
        From: "Od:",
        To: "Do:",
        Substitution: "Zastupování",
        Add: "Přidat",
        Edit: "Upravit",
        Undo: "Vrátit",
    },
};
